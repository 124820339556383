export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer'
    },
    {
      title: true,
      name: ''
    },
    {
      name: 'User details',
      url: '/userinfo',
      icon: 'icon-magnifier'
    },
    {
      name: 'Verify user',
      url: '/verifyuser',
      icon: 'icon-check'
    },
    {
      name: 'Send verification email',
      url: '/sendverifyuser',
      icon: 'icon-cursor'
    },
    {
      name: 'Send passwordless login link',
      url: '/passwordlesslogin',
      icon: 'icon-cursor'
    },
    {
      name: 'Change email address',
      url: '/changeemail',
      icon: 'icon-envelope'
    },
    {
      name: 'Block User',
      url: '/blockuser',
      icon: 'icon-lock'
    },
    {
      name: 'Unblock User',
      url: '/unblockuser',
      icon: 'icon-lock-open'
    },
    {
      name: 'Change password',
      url: '/changepassword',
      icon: 'icon-star'
    },
    {
      name: 'Reset password',
      url: '/resetpasswordrequest',
      icon: 'icon-star'
    },
    {
      name: 'Delete user',
      url: '/deleteuser',
      icon: 'icon-ban'
    },
    {
      name: 'User Manager / Admin ohje',
      url: 'https://bit.ly/2QF6EQe',
      icon: 'fa fa-info-circle',
      class: 'mt-auto',
      variant: 'info',
      attributes: { target: '_blank', rel: 'noopener' }
    },
    {
      name: 'Alma-tunnus info',
      url: 'https://www.almamedia.fi/alma-tunnus',
      icon: 'fa fa-info-circle',
      variant: 'primary',
      attributes: { target: '_blank', rel: 'noopener' }
    },
    {
      name: 'Datastudio report',
      url:
        'https://datastudio.google.com/u/0/reporting/1F5crniVdYx4iYCoNXYliOJJMg6yTDFoX',
      icon: 'icon-cloud-download',
      variant: 'primary',
      attributes: { target: '_blank', rel: 'noopener' }
    },
    {
      name: 'Google analytics',
      url: 'https://analytics.google.com/analytics/web/#',
      icon: 'fa fa-google',
      variant: 'info',
      attributes: { target: '_blank', rel: 'noopener' }
    },
    {
      name: 'Auth0 dashboard',
      url: 'https://manage.auth0.com/',
      icon: 'icon-layers',
      variant: 'primary',
      attributes: { target: '_blank', rel: 'noopener' }
    }
  ]
}
