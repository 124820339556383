import React, { Component } from 'react'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import { UserConsumer, UserProvider } from './UserContext'
import './App.scss'
import routes from './routes'
import { ToastContainer } from 'react-toastify'

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
)

const RouteWithTitle = ({ layout: Layout, user, ...route }) =>
  route.requiresAuth && !user ? (
    <Redirect to="/" />
  ) : (
    <Layout>
      <Route {...route} />
    </Layout>
  )

// Refresh JW token every 15 minutes.
// Prevents a logged in user from getting unauthorized API responses.
const refreshToken = () => {
  try {
    window.ALMA.tunnus.getIdJWT({ refresh: true })
    setInterval(() => {
      window.ALMA.tunnus.getIdJWT({ refresh: true })
    }, 900000)
  } catch (e) {
    console.log(e)
  }
}

class App extends Component {
  componentDidMount() {
    refreshToken()
  }
  render() {
    return (
      <UserProvider>
        <ToastContainer
          position="top-center"
          autoClose={4000}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <UserConsumer>
          {({ user }) => (
            <HashRouter>
              <React.Suspense fallback={loading()}>
                <Switch>
                  {routes.map(route => (
                    <RouteWithTitle
                      key={route.path || route.title}
                      user={user}
                      {...route}
                    />
                  ))}
                </Switch>
              </React.Suspense>
            </HashRouter>
          )}
        </UserConsumer>
      </UserProvider>
    )
  }
}

export default App
