import axios from 'axios'

export const getIdTokenFromLocalStorage = () => {
  try {
    const { id_token_data } = JSON.parse(localStorage.getItem('ALMA')) || {}
    const idToken = id_token_data.idToken
    return idToken
  } catch (error) {
    console.error(error)
    return ''
  }
}

const request = ({ method = 'GET', data = {}, endpoint = '', id } = {}) => {
  const idToken = getIdTokenFromLocalStorage()

  const response = axios({
    data,
    headers: {
      'Content-Type': 'application/json',
      'X-Authorization': `Bearer ${idToken}`
    },
    method,
    url: `${process.env.REACT_APP_API_URL}/users/${encodeURIComponent(id)}${
      endpoint ? `/${endpoint}` : ''
    }`
  })
  return response
}

const isBusinessUser = async idOrUser => {
  const user =
    typeof idOrUser === 'string'
      ? (await getUserDetails(idOrUser, false)).data
      : idOrUser
  return (
    user?.identities?.find(id => id.provider === 'auth0').connection ===
    'Almatunnus-Business'
  )
}

const emailRequest = ({ method = 'GET', data = {}, address } = {}) => {
  const idToken = getIdTokenFromLocalStorage()

  const response = axios({
    data,
    headers: {
      'Content-Type': 'application/json',
      'X-Authorization': `Bearer ${idToken}`
    },
    method,
    url: `${
      process.env.REACT_APP_API_URL
    }/users/user-by-email?address=${encodeURIComponent(
      address
    )}&connection=Almatunnus-Business`
  })
  return response
}

const responseOnSuccess = (message, data = null) => ({
  success: true,
  message: message,
  data: data
})

const responseOnFail = (message, data = null) => ({
  success: false,
  message: message,
  data: data
})

const notBusinessUserResponse = responseOnFail(`Error: Not a business user!`)

export const patchUser = async (data, id) => {
  const metadataEntries = Object.entries(data.user_metadata)
  const metadataKeys = metadataEntries.filter(e => e[1]).map(e => e[0])
  const keysString = metadataKeys.toString().replace(',', ', ')
  return await request({ method: 'PATCH', data: { data }, id })
    .then(res => responseOnSuccess(`${keysString} updated successfully`, res))
    .catch(err => responseOnFail(err.message, err.response))
}

export const getUserDetails = async (id, checkIfBusinessUser = true) => {
  const response = await request({
    id: id
  })
    .then(response =>
      responseOnSuccess('User info retrieved successfully.', response.data.data)
    )
    .catch(error =>
      responseOnFail(
        `Error: ${error.response.data.error}`,
        error.response.data.error
      )
    )

  if (checkIfBusinessUser && !(await isBusinessUser(response.data))) {
    return notBusinessUserResponse
  }
  return response
}

export const getUserDetailsByEmail = async address => {
  const response = await emailRequest({
    address: address.trim().toLowerCase()
  })
    .then(response =>
      responseOnSuccess('User info retrieved successfully.', response.data.data)
    )
    .catch(error =>
      responseOnFail(
        `Error: ${error.response.data.error}`,
        error.response.data.error
      )
    )
  return response
}

export const verifyUser = async id => {
  if (!(await isBusinessUser(id))) return notBusinessUserResponse
  const response = await request({
    method: 'POST',
    id: id,
    endpoint: 'verify'
  })
    .then(response => responseOnSuccess(response.data.message))
    .catch(error => responseOnFail(error.response.data.error))
  return response
}

export const unblockUser = async id => {
  if (!(await isBusinessUser(id))) return notBusinessUserResponse
  const response = await request({
    method: 'DELETE',
    id: id,
    endpoint: 'user-blocks'
  })
    .then(response => responseOnSuccess(response.data.message))
    .catch(error => responseOnFail(error.response.data.error))
  return response
}

export const blockUser = async id => {
  if (!(await isBusinessUser(id))) return notBusinessUserResponse

  const response = await request({
    method: 'POST',
    id: id,
    endpoint: 'user-blocks'
  })
    .then(response => responseOnSuccess(response.data.message))
    .catch(error => responseOnFail(error.response.data.error))
  return response
}

export const changeEmail = async (id, data) => {
  if (!(await isBusinessUser(id))) return notBusinessUserResponse
  const response = await request({
    method: 'POST',
    id: id,
    data: data,
    endpoint: 'change_email'
  })
    .then(response => responseOnSuccess(response.data.message))
    .catch(error => responseOnFail(error.response.data.error))
  return response
}

export const changePassword = async (id, data) => {
  if (!(await isBusinessUser(id))) return notBusinessUserResponse
  const response = await request({
    method: 'POST',
    id: id,
    data: data,
    endpoint: 'change_password'
  })
    .then(response => responseOnSuccess(response.data.message))
    .catch(error => responseOnFail(error.response.data.error))
  return response
}

export const deleteUser = async (id, sendNotification) => {
  if (!(await isBusinessUser(id))) return notBusinessUserResponse
  // Notify-parameter determines if user will be notified via email of account deletion
  const endpointString = sendNotification
    ? 'delete_user?notify=true'
    : 'delete_user'

  const response = await request({
    method: 'DELETE',
    id: id,
    endpoint: endpointString
  })
    .then(response => responseOnSuccess(response.data.message))
    .catch(error => responseOnFail(error.message))
  return response
}
export const unlinkIdentity = async (id, provider, indentity_id) => {
  const response = await request({
    method: 'DELETE',
    id: id,
    endpoint: `unlink_identity?provider=${provider}&identity_id=${indentity_id}`
  })
    .then(response => responseOnSuccess(response.data.message))
    .catch(error => responseOnFail(error.message))
  return response
}

export const clearAppMeta = async (id, data) => {
  const response = await request({
    method: 'POST',
    id: id,
    data: data,
    endpoint: 'clear_appmeta'
  })
    .then(response => responseOnSuccess(response.data.message))
    .catch(error => responseOnFail(error.response.data.error))
  return response
}

export const sendDeleteNotificationEmail = async id => {
  const response = await request({
    method: 'POST',
    id: id,
    endpoint: 'notify_delete'
  })
    .then(response => responseOnSuccess(response.data.message))
    .catch(error => responseOnFail(error.response.data.error))
  return response
}

// The following use itsepalvelu-api

export const sendVerificationEmail = async (id, appID) => {
  if (!(await isBusinessUser(id))) return notBusinessUserResponse
  const response = await axios({
    method: 'GET',
    url: `${
      process.env.REACT_APP_ITSEPALVELU_API_URL
    }/verify?clientId=${encodeURIComponent(appID)}&id=${encodeURIComponent(
      id
    )}&responsetype=json`
  })
    .then(response =>
      responseOnSuccess('Verification email sent successfully.')
    )
    .catch(error =>
      responseOnFail(
        `Failed to send verification email: ${error.response.data.message}`
      )
    )
  return response
}

export const sendNotificationEmail = async (id, oldAddress) => {
  const response = await axios({
    method: 'GET',
    url: `${process.env.REACT_APP_ITSEPALVELU_API_URL}/notify?clientId=${
      process.env.REACT_APP_AUTH0_CLIENTID
    }&id=${encodeURIComponent(
      id
    )}&responsetype=json&oldAddress=${encodeURIComponent(oldAddress)}`
  })
    .then(response =>
      responseOnSuccess('Notification email sent successfully.')
    )
    .catch(error =>
      responseOnFail('Failed to notify both inboxes of email change.')
    )
  return response
}
export const sendUnlinkNotificationEmail = async (id, identityName) => {
  const response = await axios({
    method: 'GET',
    url: `${process.env.REACT_APP_ITSEPALVELU_API_URL}/notify_unlink?clientId=${
      process.env.REACT_APP_AUTH0_CLIENTID
    }&id=${encodeURIComponent(
      id
    )}&responsetype=json&identityName=${encodeURIComponent(identityName)}`
  })
    .then(response =>
      responseOnSuccess('Unlink notification email sent successfully.')
    )
    .catch(error =>
      responseOnFail('Failed to notify user of identity unlinking.')
    )
  return response
}

export const sendPasswordlessLogin = async (user_id, application_id) => {
  if (!(await isBusinessUser(user_id))) return notBusinessUserResponse
  let email = ''
  const user = await getUserDetails(user_id)
  if (user.success) {
    email = user.data.email
  } else {
    return responseOnFail('Cannot find user with given ID.')
  }

  const response = await axios({
    data: {
      data: {
        email: email,
        clientId: application_id,
        send: 'link'
      }
    },
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'POST',
    url: `${process.env.REACT_APP_ITSEPALVELU_API_URL}/passwordless`
  })
    .then(response =>
      responseOnSuccess('Passwordless login link sent successfully.')
    )
    .catch(error => responseOnFail(`Failed: ${error.message}`))
  return response
}

export const resetPassword = async (id, appID) => {
  if (!(await isBusinessUser(id))) return notBusinessUserResponse
  let email = ''
  const user = await getUserDetails(id)
  if (user.success) {
    email = user.data.email
  } else {
    return responseOnFail('Cannot find user with given ID.')
  }

  const response = await axios({
    data: {
      data: {
        email: email,
        clientId: appID
      }
    },
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'POST',
    url: `${process.env.REACT_APP_ITSEPALVELU_API_URL}/request_password_change`
  })
    .then(response =>
      responseOnSuccess('Password reset request sent successfully.')
    )
    .catch(error => responseOnFail('Failed to send password reset request.'))
  return response
}

export const checkBounceStatus = async email => {
  const idToken = getIdTokenFromLocalStorage()
  const response = await axios({
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Authorization': `Bearer ${idToken}`
    },
    url: `${
      process.env.REACT_APP_API_URL
    }/users/bounce?email=${encodeURIComponent(email)}`
  })
    .then(response =>
      responseOnSuccess('Bounce status found', response.data.data)
    )
    .catch(error => responseOnFail(error.response.data.error))
  return response
}
