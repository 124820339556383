import React, { Component } from 'react'
import AlmaTunnusAuth from '@almamedia/alma-tunnus-component'
import { getIdTokenFromLocalStorage } from './api'
import jwt from 'jsonwebtoken'

const ERROR_NOT_VERIFIED =
  "Sorry, we can't let you in. We noticed that your account is not verified. Please verify your account and try again."
const ERROR_NOT_ADMIN =
  "Sorry, we can't let you in. Your account does not have required permissions to access Business User Manager."

const { Provider, Consumer } = React.createContext()

class UserProvider extends Component {
  state = {
    logout: null,
    openLogin: null,
    user: null,
    permissionError: sessionStorage.getItem('permissionError') || ''
  }

  userIsAdmin = () => {
    const idToken = getIdTokenFromLocalStorage()
    const user = jwt.decode(idToken)
    return user['https://almamedia.net/access'] === 'admin'
  }

  userIsVerified = () => {
    const idToken = getIdTokenFromLocalStorage()
    const user = jwt.decode(idToken)
    return user['email_verified']
  }

  setUser = user => {
    const userIsVerified = this.userIsVerified()
    const userIsAdmin = this.userIsAdmin()

    if (userIsVerified && userIsAdmin) {
      this.setState({ user: user })
      sessionStorage.removeItem('permissionError')
    } else {
      if (!userIsVerified) {
        sessionStorage.setItem('permissionError', ERROR_NOT_VERIFIED)
      } else {
        sessionStorage.setItem('permissionError', ERROR_NOT_ADMIN)
      }
      this.logout()
    }
  }

  logout = () => {
    const { logout } = this.state
    if (logout !== null) {
      logout()
      this.setState({ user: null })
    }
  }

  onLoginCallback = async (error, success) => {
    if (error !== null) {
      console.error(error)
      return
    }
    this.setUser(success.user)
  }

  onCheckSessionCallback = async user => {
    if (!user) {
      return
    }
    this.setUser(user)
  }

  render() {
    const { children } = this.props
    const { openLogin, user, permissionError } = this.state

    return (
      <Provider
        value={{
          logout: this.logout,
          openLogin,
          user,
          permissionError
        }}
      >
        <AlmaTunnusAuth
          domain={process.env.REACT_APP_DOMAIN}
          clientID={process.env.REACT_APP_AUTH0_CLIENTID}
          audience={process.env.REACT_APP_AUDIENCE}
          redirectUrl={process.env.REACT_APP_REDIRECT_URL}
          debugMode={true}
          scope="openid profile email"
          realm="Almatunnus"
          loginCallback={this.onLoginCallback}
          checkSessionCallback={this.onCheckSessionCallback}
          loginNotification={false}
          actions={({ openAlmaLogin, logout }) => {
            this.setState({
              logout,
              openLogin: openAlmaLogin
            })
          }}
          coLogin={true}
          thirdPartyCookieCheckUrl={
            process.env.REACT_APP_THIRD_PARTY_COOKIE_CHECK_URL
          }
          disableSignUp
          lng={'en'}
        />
        {children}
      </Provider>
    )
  }
}

export { UserProvider, Consumer as UserConsumer }
