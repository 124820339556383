import React from 'react'
import DefaultLayout from './containers/DefaultLayout/DefaultLayout'
import WelcomeLayout from './containers/WelcomeLayout'

const UserInfo = React.lazy(() => import('./views/UserInfo'))
const Dashboard = React.lazy(() => import('./views/Dashboard'))
const VerifyUser = React.lazy(() => import('./views/VerifyUser'))
const SendVerifyUser = React.lazy(() => import('./views/SendVerifyUser'))
const BlockUser = React.lazy(() => import('./views/BlockUser'))
const UnblockUser = React.lazy(() => import('./views/UnblockUser'))
const DeleteUser = React.lazy(() => import('./views/DeleteUser'))
const ChangePassword = React.lazy(() => import('./views/ChangePassword'))
const ChangeEmail = React.lazy(() => import('./views/ChangeEmail'))
const Welcome = React.lazy(() => import('./views/Welcome'))
const ResetPasswordRequest = React.lazy(() =>
  import('./views/ResetPasswordRequest')
)
const PasswordlessLogin = React.lazy(() => import('./views/PasswordlessLogin'))

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    component: Welcome,
    exact: true,
    layout: WelcomeLayout,
    path: '/'
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    requiresAuth: true,
    layout: DefaultLayout
  },
  {
    path: '/userinfo',
    exact: true,
    name: 'User info',
    component: UserInfo,
    requiresAuth: true,
    layout: DefaultLayout
  },
  {
    path: '/verifyuser',
    exact: true,
    name: 'Verify user',
    component: VerifyUser,
    requiresAuth: true,
    layout: DefaultLayout
  },
  {
    path: '/sendverifyuser',
    exact: true,
    name: 'Send user verification',
    component: SendVerifyUser,
    requiresAuth: true,
    layout: DefaultLayout
  },
  {
    path: '/blockuser',
    exact: true,
    name: 'Block user',
    component: BlockUser,
    requiresAuth: true,
    layout: DefaultLayout
  },
  {
    path: '/unblockuser',
    exact: true,
    name: 'Unblock user',
    component: UnblockUser,
    requiresAuth: true,
    layout: DefaultLayout
  },
  {
    path: '/deleteuser',
    exact: true,
    name: 'Delete user',
    component: DeleteUser,
    requiresAuth: true,
    layout: DefaultLayout
  },
  {
    path: '/changeemail',
    exact: true,
    name: 'Change email',
    component: ChangeEmail,
    requiresAuth: true,
    layout: DefaultLayout
  },
  {
    path: '/changepassword',
    exact: true,
    name: 'Change password',
    component: ChangePassword,
    requiresAuth: true,
    layout: DefaultLayout
  },
  {
    path: '/resetpasswordrequest',
    exact: true,
    name: 'Reset password',
    component: ResetPasswordRequest,
    requiresAuth: true,
    layout: DefaultLayout
  },
  {
    path: '/passwordlesslogin',
    exact: true,
    name: 'Send passwordless login link',
    component: PasswordlessLogin,
    requiresAuth: true,
    layout: DefaultLayout
  }
]

export default routes
